import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/User2/Documents/flits-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "driver-apps"
    }}>{`Driver Apps`}</h1>
    <h2 {...{
      "id": "memulai-flits-driver-apps"
    }}>{`Memulai Flits Driver Apps`}</h2>
    <p>{`Sebelum dapat memanfaatkan fitur-fitur yang ada pada Flits Driver App, pastikan nama anda telah terdaftar sebagai supir dari Perusahaan yang menggunakan Software Flits.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Download Flits Driver App dari PlaystoreTM  atau dapat klik link berikut,
`}<a parentName="p" {...{
            "href": "https://play.google.com/store/apps/details?id=com.nurosoft.lorrydrivertest"
          }}>{`https://play.google.com/store/apps/details?id=com.nurosoft.lorrydrivertest`}</a>{` `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Login dengan cara memasukkan Nama Database Perusahaan, Nomor Telepon yang terdaftar dan Password. `}</p>
        <img src='/public/img/Driver/Login.png' style={{
          "width": "30%"
        }} />
      </li>
      <li parentName="ol">
        <p parentName="li">{`Setelah semua field telah terisi, klik tombol masuk`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Saat pertama kali anda melakukan login, aplikasi ini mengharuskan anda untuk mengizinkan akses lokasi anda (klik tombol allow pada pop-up permission)`}</p>
        <img src='/public/img/Driver/Popup.jpeg' style={{
          "width": "30%"
        }} />
      </li>
      <li parentName="ol">
        <p parentName="li">{`Ketika GPS anda dimatikan, akan muncul tampilan seperti berikut, anda hanya perlu klik tombol Pengaturan kemudian aktifkan GPS anda (Cara mengaktifkan GPS di hp Android: `}<a parentName="p" {...{
            "href": "https://support.google.com/accounts/answer/3467281?hl=id"
          }}>{`https://support.google.com/accounts/answer/3467281?hl=id`}</a>{`)`}</p>
        <img src='/public/img/Driver/GPS non active.png' style={{
          "width": "30%"
        }} />
      </li>
      <li parentName="ol">
        <p parentName="li">{`Anda telah siap menerima pesanan dari Admin Flits TMS`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "tahapan-menjalankan-order"
    }}>{`Tahapan Menjalankan Order`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Saat admin sales telah menginput order pengiriman dari customer, anda akan mendapatkan notifikasi dari Aplikasi Flits Driver terkait pengiriman (SO) tersebut. Klik notifikasi tersebut untuk melihat detail pengiriman.`}</p>
        <img src='/public/img/Driver/Order.png' style={{
          "width": "30%"
        }} />
        <p parentName="li"><strong parentName="p">{`Keterangan :`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Nomor Order`}</strong></p>
            <p parentName="li">{`  Dalam menjalankan setiap order, anda akan mendapatkan nomor order yang berbeda`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Status Order`}</strong></p>
            <p parentName="li">{`  Status Order akan selalu terupdate setelah anda selesai upload surat jalan dan surat tanda terima`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Nomor Polisi`}</strong></p>
            <p parentName="li">{`  Menunjukkan kendaraan yang akan anda gunakan dalam menyelesaikan Order`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Nama Pelanggan`}</strong></p>
            <p parentName="li">{`  Pelanggan yang melakukan Order`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Nama Item`}</strong></p>
            <p parentName="li">{`  Barang yang akan anda kirim ke lokasi tujuan`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Rute`}</strong></p>
            <p parentName="li">{`  Menunjukkan lokasi Kota start dan lokasi  Kota tujuan`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Alamat`}</strong></p>
            <p parentName="li">{`  Deskripsi lengkap lokasi kota start`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Info Terkait Order`}</strong></p>
            <p parentName="li">{`  Menunjukkan kapan dan siapa Admin yang membuat order`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Foto Surat Jalan`}</strong></p>
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Segera menuju lokasi pengambilan barang menggunakan kendaraan yang telah ditentukan`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Foto Surat Jalan yang anda terima saat melakukan pengambilan barang, kemudian Unggah dan Simpan. `}</p>
        <img src='/public/img/Driver/Surat Jalan.png' style={{
          "width": "30%"
        }} />
      </li>
      <li parentName="ol">
        <p parentName="li">{`Setelah Mengunggah Foto Surat Jalan, status Order akan otomatis terupdate menjadi “Dalam Perjalanan”`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Segera Lakukan Pengiriman ke Kota tujuan, kemudian Foto dan Upload Tanda Terima apabila barang telah sampai tujuan`}</p>
        <img src='/public/img/Driver/Tanda Terima.png' style={{
          "width": "30%"
        }} />
      </li>
      <li parentName="ol">
        <p parentName="li">{`Tugas Anda telah selesai dan siap menerima Order baru dari Admin Flits TMS`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "ubah-kata-sandi"
    }}>{`Ubah Kata Sandi`}</h2>
    <p>{`Dalam rangka mendapatkan pengamanan terhadap akun yang dimiliki, supir disarankan untuk mengganti kata sandi secara berkala. Berikut merupakan langkah-langkah untuk mengubah kata sandi :`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Klik tombol Setting yang berada tepat di pojok kanan atas`}</p>
        <img src='/public/img/Driver/Setting.png' style={{
          "width": "30%"
        }} />
      </li>
      <li parentName="ol">
        <p parentName="li">{`Pada side menu yang muncul, pilih menu “Ubah Kata Sandi”`}</p>
        <img src='/public/img/Driver/Side Menu.png' style={{
          "width": "30%"
        }} />
      </li>
      <li parentName="ol">
        <p parentName="li">{`Ubah kata sandi anda dengan cara memasukkan kata sandi yang lama, kemudian kata sandi yang baru, lalu konfirmasi kata sandi, dan terakhir klik tombol simpan untuk melakukan perubahan.`}</p>
        <img src='/public/img/Driver/Change Password.png' style={{
          "width": "30%"
        }} />
        <p parentName="li"><strong parentName="p">{`Catatan :`}</strong></p>
        <p parentName="li">{`Ketentuan untuk Kata Sandi Flits Driver Apps adalah :`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`- Minimal terdiri dari 8 karakter
- Mengandung huruf kecil dan huruf besar
- Mengandung spesial karakter 
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Password anda berhasil diubah`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      